@import '../mixins.scss';

.button {
  @include Font(15);
  line-height: calc(28/15);
  padding: 0 30px;
  min-height: 52px;
  border-radius: 50rem;
  color: $c-black;
  transition: .3s ease;
  white-space: nowrap;
  @extend %flex-center;

  &:hover {
    background-color: $c-accent2;
  }

  &:active, &:focus {
    background-color: $c-blue;
    color: $c-white
  }

  @include bp-max(lg) {
    @include Font(14);
    line-height: calc(24/14);
  }

  &--gray {
    background-color: $c-accent;
  }

  &--white {
    background-color: $c-white;
  }
}