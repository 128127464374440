/* RESETTING INITIAL CSS SETTINGS. NORMALIZE CSS FOR BROWSERS */

// css reset start

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// css reset end

// css modify start
a {
  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
  }
}

input {
  outline: none;
  border: none;

  &::placeholder,
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &:-moz-placeholder,
  &:-ms-input-placeholder {
    color: #A6ABB9;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &:focus::placeholder,
  &:focus::-webkit-input-placeholder,
  &:focus::-moz-placeholder,
  &:focus:-moz-placeholder,
  &:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &[type=number]::-webkit-outer-spin-button,
  &[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

a, button, input, textarea, span {
  border: none;
  outline: none;
  outline-offset: 0;

  :active, :hover, :focus {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
}

a, button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidden {
  display: none !important;
}

hr {
  border: unset;
}

// css modify end

// self normalizes

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: transparent;
}

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  &[type="email"],
  &[type="number"],
  &[type="password"],
  &[type="search"],
  &[type="tel"],
  &[type="text"],
  &[type="url"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

strong, b {
  font-weight: 700;
}

// self normalizes end