.social-link {
  background-color: $c-white;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  @extend %flex-center;
  transition: .3s ease;

  svg {
    color: $c-black;
  }

  &:hover {
    background-color: $c-accent2;
  }

  &:active, &:focus {
    background-color: $c-blue;
    color: $c-white;

    svg {
      color: $c-white;
    }
  }
}