@import './vars.scss';

@mixin showVisible {
	opacity: 1;
	visibility: visible;
}

@mixin hideVisible {
	opacity: 0;
	visibility: hidden;
}

@mixin bp-max($point) {
	@if $point ==sm {
		@media (max-width: calc($sm - .02px)) {
			@content;
		}
	}

	@else if $point ==md {
		@media (max-width: calc($md - .02px)) {
			@content;
		}
	}

	@else if $point ==lg {
		@media (max-width: calc($lg - .02px)) {
			@content;
		}
	}

	@else if $point ==xl {
		@media (max-width: calc($xl - .02px)) {
			@content;
		}
	}

	@else if $point ==xxl {
		@media (max-width: calc($xxl - .02px)) {
			@content;
		}
	}

	@else if $point {

		// allows custom breakpoints
		@media (max-width: calc($point - .02px)) {
			@content;
		}
	}
}

@mixin bp-min($point) {
	@if $point ==sm {
		@media (min-width: $sm) {
			@content;
		}
	}

	@else if $point ==md {
		@media (min-width: $md) {
			@content;
		}
	}

	@else if $point ==lg {
		@media (min-width: $lg) {
			@content;
		}
	}

	@else if $point ==xl {
		@media (min-width: $xl) {
			@content;
		}
	}

	@else if $point ==xxl {
		@media (min-width: $xxl) {
			@content;
		}
	}

	@else if $point {
		@media (min-width: $point) {
			@content;
		}
	}
}

@mixin Font($size, $weight: false, $important: false) {
	font-size: #{$size}px #{if($important, unquote(' !important'), '')};

	@if $weight  !=false {
		font-weight: $weight #{if($important, unquote(' !important'), '')};
	}
}

@mixin Regular($size: $defaultFontSize, $important: false) {
	@include Font($size, 400, $important);
}

@mixin Semibold($size: $defaultFontSize, $important: false) {
	@include Font($size, 500, $important);
}

@mixin Bold($size: $defaultFontSize, $important: false) {
	@include Font($size, 700, $important);
}

@mixin Scroll() {

	::-webkit-scrollbar,
	&::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb {
		background: $c-black;
		border-radius: 4px;
	}

	::-webkit-scrollbar-track,
	&::-webkit-scrollbar-track {
		background: $bodyBgGradient;
	}

	scrollbar-width: thin;
}

%abs-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

%abs-full {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}