@import 'src/styles/mixins';

body {
  overflow-x: hidden;
  font-family: $manrope;
  background: $bodyBgGradient;
  min-height: 100vh;
  @include Scroll();
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img,
picture {
  max-width: 100%;
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1300px;

  @include bp-max(lg) {
    padding: 0 60px;
  }

  @include bp-max(md) {
    padding: 0 20px;
  }

  @include bp-max(sm) {
    padding: 0 10px;
  }
}

.hidden {
  display: none !important;
}

.section-gap {
  padding-top: 85px;
  padding-bottom: 85px;

  @include bp-max(lg) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}