@keyframes bg-transition {
  from { background: $c-black; }
  to { background: $c-white; }
}

@keyframes after-transition {
  from { background: rgba(0, 0, 0, 0.4); }
  to { background: transparent; }
}

@keyframes slideLine {
  from {transform: translate3d(0, 0, 0)}
  to {transform: translate3d(-50%, 0, 0)}
}

@keyframes grow {
  from {max-height: 0;}
  to {max-height: 100%;}
}

@keyframes fade {
  from {@include hideVisible();}
  to {@include showVisible();}
}