// fonts
$manrope: 'Manrope', sans-serif;

// colors
$c-blue: #0738BA;
$c-green: #009B3A;
$c-black: #000;
$c-white: #FFFFFF;
$c-accent: #EBEEF2;
$c-accent2: #D8E6F8;

$mainGradient: linear-gradient(129.27deg, $c-green 26.18%, $c-blue 100%);
$cardGradient: linear-gradient(42.08deg, $c-green 17.09%, #0000C4 134.01%);
$bgGradient: linear-gradient(68.24deg, $c-white 37.74%, #E2E6F0 70.03%);
$bodyBgGradient: linear-gradient(183.46deg, $c-white 2.85%, #E2E6F0 201.72%);

// sizes
$defaultFontSize: 15px;
$headerHeight: 90px;

// breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;