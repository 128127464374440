@import '../mixins';

.title {
  @include Semibold(90);

  @include bp-max(xxl) {
    @include Font(75);
  }

  @include bp-max(lg) {
    @include Font(48);
  }

  @include bp-max(375px) {
    @include Font(42);
  }
}

.title2 {
  @include Semibold(70);
  line-height: calc(80/70);
  text-transform: uppercase;

  @include bp-max(xxl) {
    @include Font(60);
  }

  @include bp-max(lg) {
    @include Font(43);
    line-height: calc(60/43);
  }
}

.subheading {
  @include Regular(40);
  line-height: calc(48/40);

  @include bp-max(xxl) {
    @include Font(30);
  }

  @include bp-max(lg) {
    @include Font(22);
    line-height: calc(33/22);
  }
}

.subheading2 {
  @include Semibold(21);
  line-height: calc(29/21);

  @include bp-max(xxl) {
    @include Font(20);
  }

  @include bp-max(lg) {
    @include Font(18);
    line-height: calc(21/18);
  }
}

.default1 {
  @include Regular(18);
  line-height: calc(30/18);

  @include bp-max(xxl) {
    @include Font(16);
  }

  @include bp-max(lg) {
    @include Font(15);
    line-height: calc(25/15);
  }
}

.default2 {
  @include Regular(15);
  line-height: calc(28/15);

  @include bp-max(xxl) {
    @include Font(14);
  }

  @include bp-max(lg) {
    @include Font(12);
    line-height: calc(25/12);
  }
}
